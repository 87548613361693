<template>
    <section class="foot">
        <b-container>
            <b-row>
                <b-col l="6">
                    <div class="about">
                        <p v-text="name"></p>
                        <h4>Copyright © 2020. Company name All rights reserved.</h4>
                    </div>
                </b-col>
                <b-col l="6">
                    <div class="caller">
                        <p v-text="contact"></p>
                        <h3 v-text="mobile"></h3>

                        <div class="qr">
                            <div class="flag dou-yin-flag">
                                <img :src="mini_program.tag" alt="">
                                <div class="qr dou-yin-qr">
                                    <img :src="mini_program.cover" alt="">
                                </div>
                            </div>

                            <div class="flag we-chat-flag">
                                <img :src="we_chat.tag" alt="">
                                <div class="qr we-chat-qr">
                                    <img :src="we_chat.cover" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>

</template>

<script>
    export default {
        name: "Foot",
        data () {
            return {
                name: '自力网络创立于2020年，是一家集文化传媒、电子商务于一体的民营互联网企业。旗下品牌包括华村购、皖村购，其中皖村购平台已签约千余种产品。',
                contact: '商务联系',
                mobile: '177-6441-2196',

                mini_program: {
                    tag: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/mini-program-tag.png?x-oss-process=style/basic',
                    cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/min-program.jpg?x-oss-process=style/basic'
                },
                we_chat: {
                    tag: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/foot_2.png?x-oss-process=style/basic',
                    cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/qr_code.jpg?x-oss-process=style/basic'
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .foot {
        padding: 10rem 0;

        .about {
            color: #666667;

            p {
                font-size: 1.4rem;
                line-height: 2.4rem;
            }

            h4 {
                font-size: 1.4rem;
                font-weight: normal;
                margin-top: 4rem;
            }
        }

        .caller {
            float: right;
            width: 24rem;
            padding: 0 2rem;

            p {
                font-size: 1.6rem;
                margin: 0 0 1rem;
            }

            h3 {
                font-size: 2.4rem;
                margin: 0;
                margin-bottom: 3rem;
            }

            .qr {
                position: relative;

                .dou-yin-qr {
                    display: none;
                    position: absolute;
                    width: 20rem;
                    left: -16rem;
                    bottom: 1rem;

                    img {
                        width: 100%;
                    }
                }

                .we-chat-qr {
                    display: none;
                    position: absolute;
                    width: 23rem;
                    left: 0;
                    bottom: 1rem;

                    img {
                        width: 100%;
                    }
                }

                .flag {
                    &:hover .qr {
                        display: block;
                        padding: 1rem;
                        border: 2px solid #333;
                        background-color: white;
                    }

                    float: left;
                    margin-right: 2rem;

                    > img {
                        &:first-child {
                            margin-left: 0;
                        }

                        margin-left: 1.8rem;
                        cursor: pointer;
                        width: 3.2rem;
                        height: 3.2rem;
                    }
                }
            }
        }
    }
</style>
