<template>
    <div>
        <Nav></Nav>
        <Welcome></Welcome>
    </div>
</template>

<script>
    import Nav from '@/components/Nav.vue'
    import Welcome from '@/components/Welcome.vue'

    export default {
        name: 'Home',
        components: {
            Nav, Welcome
        }
    }
</script>
