<template>
    <div>
        <section class="blk2">
            <b-container>
                <b-row>
                    <b-col cols="5">
                        <img class="l_img" :src="block2.cover" :alt="block2.title">
                    </b-col>
                    <b-col cols="7">
                        <div class="blk-head">
                            <h6 class="tip" v-text="block2.tip"></h6>
                            <h2 class="title" v-text="block2.title"></h2>
                            <p class="desc" v-text="block2.desc"></p>
                            <p v-text="block2.desc2"></p>
                        </div>

                        <b-row>
                            <b-col cols="6" v-for="item in block2.content" :key="item.name">
                                <dl>
                                    <img :src="item.cover" :alt="item.name"/>
                                    <dt v-text="item.name"></dt>
                                </dl>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="blk3">
            <b-container>
                <b-row>
                    <b-col cols="12" class="blk-head text-center">
                        <h6 class="tip" v-text="block3.tip"></h6>
                        <h2 class="title" v-text="block3.title"></h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-for="item in block3.content" :key="item.name" cols="3">
                        <div class="box-icon">
                            <h3>{{ item.name }}</h3>
                            <span></span>
                            <p>{{ item.desc }}</p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="blk6">
            <b-container>
                <b-row>
                    <b-col cols="12">
                        <div class="blk-head text-center">
                            <h6 class="tip" v-text="block6.tip"></h6>
                            <h2 class="title" v-text="block6.title"></h2>
                        </div>
                    </b-col>
                    <b-col cols="12">
                        <img :src="block6.content.cover" :alt="block6.tip" style="width: 100%"/>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Welcome',
        data () {
            return {
                block2: {
                    tip: 'About Us',
                    title: '关于我们',
                    desc: '自力网络创立于2020年，是一家集文化传媒、电子商务于一体的民营互联网企业。',
                    desc2: '旗下品牌包括华村购、皖村购，其中皖村购平台已签约千余种产品。',
                    cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/blk_1_left.png?x-oss-process=style/basic',
                    content: [
                        { name: '皖村购公众号 · 扫码关注', cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/qr_code.jpg?x-oss-process=style/basic'},
                        { name: '皖村购小程序 · 扫码选购', cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/min-program.jpg?x-oss-process=style/basic'},
                    ]
                },
                block3: {
                    tip: 'Vision',
                    title: '公司愿景',
                    content: [
                        {name: '自力', desc: '坚持自力更生、自主发展是建立在企业自我发展的需求及意愿的基础之上，是内在的、主动的发展，也是自力网络最主要的核心理念。'},
                        {name: '创新', desc: '在高速变化的互联网时代，创新正在成为每个组织和个人必须具备的能力，它不仅是企业可持续发展的源动力，而且是推动社会进步的有效途径。'},
                        {name: '互联', desc: '我们正从今天的“物联网”走入“万物互联”的时代，万物互联将信息转化为行动，给企业，个人和国家创造新的功能，并带来更加丰富的体验和前所未有的经济发展机遇。'},
                        {name: '共赢', desc: '经济全球化的大背景下共赢是一种卓有远见的和谐发展，既利人，又利己；既合作，又竞争；既相互比赛，又相互激励，达到的效果远远比单赢要大得多，远得多。'},
                    ],
                },
                block6: {
                    tip: 'Map',
                    title: '布局版图',
                    content: {
                        cover: 'https://zili-oss.oss-accelerate.aliyuncs.com/www/map.png?x-oss-process=style/basic'
                    }
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .blk2 {
        overflow: hidden;
        padding: 20rem 0;

        .l_img {
            width: 100%;
        }

        .desc {
            font-size: 2rem;
            line-height: 1.5;
            font-weight: 600;
            margin-top: 1.4rem;
        }

        p {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.5;
            color: #666667;
        }

        dl {
            &:last-child {
                margin-right: 0;
            }

            margin-right: 2rem;
            margin-top: 4rem;
            height: 38.4rem;
            text-align: center;
            border: 1px solid #efeff4;

            dt {
                font-size: 1.6rem;
            }

            dd {
                line-height: 5rem;
            }

            img {
                margin: 1.1rem;
                width: 26rem;
                height: 26rem;
            }
        }
    }

    .blk3 {
        overflow: hidden;
        padding: 20rem 0;
        background-color: #f8f8fa;

        .blk-head .title {
            margin-bottom: 10rem;
        }

        .box-icon {
            &:hover span {
                background-color: #4870c7;
            }

            margin-top: 1rem;
            background-color: white;
            text-align: center;
            padding: 12rem 4rem;
            height: 45rem;

            > span {
                width: 3rem;
                height: 0.2rem;
                display: inline-block;
                margin: 1.2rem auto 0;
                background-color: #adb0b8;
            }

            h3 {
                color: #29292a;
                font-weight: 600;
                font-size: 2.8rem;
                line-height: 1;
                margin: 0;
            }

            p {
                color: #666667;
                margin-top: 4rem;
                font-size: 1.4rem;
                text-align: left;
                line-height: 2.4rem;
            }
        }
    }

    .blk6 {
        overflow: hidden;
        padding: 20rem 0;

        .blk-head .title {
            margin-bottom: 10rem;
        }
    }
</style>
