<template>
    <section class="nav" :class="name">
        <b-container>
            <b-row>
                <b-col l="6">
                    <div class="logo">
                        <a href="/">
                            <img src="https://zili-oss.oss-accelerate.aliyuncs.com/www/logo_250.png?x-oss-process=style/basic" alt="">
                        </a>
                    </div>
                </b-col>
                <b-col l="6">
                    <ul class="top-right">
                        <li class="page_item page-item-2">
                            <router-link to="contact">联系我们</router-link>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "Nav",
        props: {
            name: {
                inputName: String,
                default: 'home'
            },
        }
    }
</script>

<style lang="less" scoped>
    .nav {
        &:after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;
            font-size: 0;
        }

        width: 100%;
        overflow: hidden;

        &.home {
            height: 60rem;
            background: url("https://zili-oss.oss-accelerate.aliyuncs.com/www/nav_bg_2x.png?x-oss-process=style/basic") no-repeat bottom center / cover;
        }

        &.about {
            height: 40rem;
            background: url("https://zili-oss.oss-accelerate.aliyuncs.com/www/contact_bg_2x.png?x-oss-process=style/basic") no-repeat top center / cover;
        }

        .logo {
            height: 5.6rem;
            overflow: hidden;

            a {
                margin-top: 1.8rem;
                height: 2.4rem;
                display: block;

                img {
                    height: 2.4rem;
                }
            }
        }

        ul.top-right {
            height: 3.5rem;
            padding-left: 0;
            margin-top: 1.1rem;
            float: right;

            li {
                &:first-child {
                    padding-left: 0;
                }

                padding-left: 5rem;
                list-style-type: none;
                float: left;

                a {
                    color: #4B74C6;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
</style>
