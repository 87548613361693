import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import AmapVue from '@amap/amap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

AmapVue.config.key = '2dfda4783753ba82c3823b1716537418';
Vue.use(AmapVue);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    const { title  = '' } = to.meta;

    if (title.length) {
        document.title = title;
    }

    next();
});

new Vue({router, store, render: h => h(App)}).$mount('#app');
