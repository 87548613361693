<template>
    <div id="app">
        <keep-alive>
            <router-view/>
        </keep-alive>
        <Foot></Foot>
    </div>
</template>

<script>
    // import Nav from '@/components/Nav.vue'
    import Foot from '@/components/Foot.vue'

    export default {
        name: 'Home',
        components: {
            Foot
        }
    }
</script>

<style lang="less">
    @media (max-width: 991px) {
        .container {
            width: 36rem;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 140rem;
        }
    }

    html {
        font-size: 62.5%;

        a {
            &:hover {
                text-decoration: none;
            }

            text-decoration: none;
        }
    }

    .blk-head {
        .tip {
            margin: 0;
            color: #98989c;
            font-size: 1.8rem;
            line-height: 2rem;
        }

        .title {
            margin: 1rem 0;
            color: #29292a;
            font-size: 4.8rem;
            font-weight: 600;
            line-height: 5rem;
        }
    }
</style>
