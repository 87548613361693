import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {path: '/', name: 'Home', component: Home, meta: {title: '首页 - 安徽自力网络科技有限公司'}},
  {path: '/contact', name: 'Contact', component: () => import('../views/Contact.vue'), meta: {title: '联系我们 - 安徽自力网络科技有限公司'}}
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
